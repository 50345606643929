import React, { useEffect, useState } from "react";
import {
    CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CAccordionBody,
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
} from "@coreui/react";
import RoundedMultiSelect from "../components/RoundedMultiSelect";
import {formatDate, getTimeString, weekDays} from "../../Utils/DateTimeUtils";
import CIcon from "@coreui/icons-react";
import {cilLocationPin, cilUser} from "@coreui/icons";

const Programs = () => {
    const programTypes = [
        {
            label: "Sports Lessons",
            value: 1,
        },
        {
            label: "Arts & Crafts Activities",
            value: 2,
        },
    ];

    const programs = [
        {
            name: "Beginner Pickleball",
            description: "Join our beginner boot camp! You will learn the basic stroke mechanics, rules/scoring, and basic shots needed to get you playing matches on your own! No equipment needed!",
            image: "https://i.postimg.cc/cJ8ZG5ms/365368536.jpg",
            type: 1,
            sessions: [
                {
                    dayOfTheWeek: 4,
                    startTime: "18:00:00",
                    endTime: "19:00:00",
                    location: "Highland Park Pickleball Court",
                    sessionsCount: 12,
                    startDate: "2024-06-12",
                    instructor: "Jerry Tudor",
                    price: 100,
                    availableSeats: 16,
                    totalSeats: 20,
                },
                {
                    dayOfTheWeek: 0,
                    startTime: "18:00:00",
                    endTime: "19:00:00",
                    location: "Highland Park Pickleball Court",
                    sessionsCount: 12,
                    startDate: "2024-06-21",
                    instructor: "Jerry Tudor",
                    price: 100,
                    availableSeats: 16,
                    totalSeats: 20,
                },
            ]
        },
        {
            name: "Kids Art and Crafts",
            description: "Our program encourages creativity through hands-on projects like painting, drawing, and sculpture. Children will explore different materials and techniques in a fun. Perfect for young artists of all skill levels!",
            image: "https://i.postimg.cc/FFkSn9cq/450086308.jpg",
            type: 2,
            sessions: [
                {
                    dayOfTheWeek: 1,
                    startTime: "18:00:00",
                    endTime: "19:00:00",
                    location: "Foothill Classroom",
                    sessionsCount: 12,
                    startDate: "2024-11-03",
                    instructor: "Lindsey Gill",
                    price: 100,
                    availableSeats: 16,
                    totalSeats: 20,
                },
                {
                    dayOfTheWeek: 3,
                    startTime: "18:00:00",
                    endTime: "19:00:00",
                    location: "Foothill Classroom",
                    sessionsCount: 12,
                    startDate: "2024-11-26",
                    instructor: "Lindsey Gill",
                    price: 100,
                    availableSeats: 16,
                    totalSeats: 20,
                },
            ]
        },
        {
            name: "Advanced Yoga",
            description: "Elevate your practice with our advanced yoga sessions. Focus on flexibility, strength, and mindfulness with an experienced instructor. Suitable for those with a solid foundation in yoga.",
            image: "https://i.postimg.cc/wMQr4N5k/yoga-class.jpg",
            type: 3,
            sessions: [
                {
                    dayOfTheWeek: 1,
                    startTime: "09:00:00",
                    endTime: "10:30:00",
                    location: "Talonto Peak Yoga Studio",
                    sessionsCount: 8,
                    startDate: "2025-09-29",
                    instructor: "Sophia Richards",
                    price: 120,
                    availableSeats: 10,
                    totalSeats: 15,
                },
                {
                    dayOfTheWeek: 6,
                    startTime: "09:00:00",
                    endTime: "10:30:00",
                    location: "Mount Daphnes View Yoga Classroom",
                    sessionsCount: 8,
                    startDate: "2025-10-04",
                    instructor: "Sophia Richards",
                    price: 120,
                    availableSeats: 10,
                    totalSeats: 15,
                },
            ]
        },
        {
            name: "Cooking for Beginners",
            description: "Get started in the kitchen with our cooking for beginners course. Learn basic cooking techniques, easy recipes, and meal prep skills to start making delicious dishes at home.",
            image: "https://i.postimg.cc/NFcVxDXM/cooking-class.jpg",
            type: 4,
            sessions: [
                {
                    dayOfTheWeek: 2,
                    startTime: "17:00:00",
                    endTime: "19:00:00",
                    location: "Oseira Culinary School",
                    sessionsCount: 6,
                    startDate: "2025-04-29",
                    instructor: "Emily Johnson",
                    price: 80,
                    availableSeats: 12,
                    totalSeats: 15,
                },
                {
                    dayOfTheWeek: 0,
                    startTime: "17:00:00",
                    endTime: "19:00:00",
                    location: "Oseira Culinary School",
                    sessionsCount: 6,
                    startDate: "2025-05-18",
                    instructor: "Emily Johnson",
                    price: 80,
                    availableSeats: 12,
                    totalSeats: 15,
                },
            ]
        },
    ]

    const [displayedPrograms, setDisplayedPrograms] = useState([]);
    const [filters, setFilters] = useState({});

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const updateFiltersMultiselect = (selectedOptions, actionMeta) => {
        setFilters({...filters, [actionMeta.name]: selectedOptions});
    };

    const applyFilters = (programs, filters) => {
        return programs.filter(program => {
            if (filters.name) {
                const searchTerm = filters.name.toLowerCase();
                if (!(program.name.toLowerCase().includes(searchTerm))) {
                    return false;
                }
            }

            if (filters.types?.length && !filters.types.map(type => type.value).includes(program.type)) {
                return false;
            }

            return true;
        });
    };

    useEffect(() => {
        setDisplayedPrograms(applyFilters(programs, filters));
    }, [programs, filters]);

    return (
        <>
            <div class="container pt-2">
                <CForm className="row gy-2 gx-3 align-items-end py-3">
                    <CCol className="col-5 col-md-2">
                        <CFormLabel htmlFor="name" className="mb-1">Name</CFormLabel>
                        <CFormInput name="name" placeholder="Search..."
                                    style={{borderRadius: "2rem"}} onChange={updateFilters}
                        />
                    </CCol>
                    <CCol className="col-7 col-md-3">
                        <CFormLabel htmlFor="types" className="mb-1">Type</CFormLabel>
                        <RoundedMultiSelect
                            name="types"
                            onChange={updateFiltersMultiselect}
                            options={programTypes}
                        />
                    </CCol>
                </CForm>
            </div>
            <div className="px-5">
                <hr/>
            </div>
            <div className="container pt-3">
                {
                    displayedPrograms.map(program =>
                        <CAccordion className="pb-3">
                            <CAccordionItem>
                                <CAccordionHeader>
                                    <div className="flex-row">
                                        <div className="col-3 col-md-2 col-lg-1">
                                            <img alt="program" className="w-100 h-100" style={{
                                                borderRadius: "12px",
                                                objectFit: "cover",
                                            }} src={program.image}/>
                                        </div>
                                        <div className="col-9 col-md-10 col-lg-11 px-3" style={{
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3,
                                        }}>
                                            <b>{program.name}</b>
                                            <br/>
                                            <span>{program.description}</span>
                                        </div>
                                    </div>
                                </CAccordionHeader>
                                <CAccordionBody className="py-3">
                                    {
                                        program.sessions.map((session, index) =>
                                            <>
                                                {index === 0 ? null : <hr/>}
                                                <div className="flex-row flex-wrap gap-3 align-items-center">
                                                    <div className="col-12 col-md-5 col-lg-4 ps-2 pt-2 pt-md-0">
                                                        <span>{weekDays[session.dayOfTheWeek]}s, {getTimeString(session.startTime, true)} - {getTimeString(session.endTime, true)}</span>
                                                        <br/>
                                                        <span><CIcon icon={cilLocationPin}/> {session.location}</span>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-4 ps-2 ps-m-0">
                                                        <span>{session.sessionsCount} sessions • Starting {formatDate(session.startDate, null, false)}</span>
                                                        <br/>
                                                        <span><CIcon icon={cilUser}/> {session.instructor}</span>
                                                    </div>
                                                    <div className="col-12 col-md-1 col-lg-2 ps-2 ps-m-0">
                                                        <span>${session.price}</span>
                                                        <br/>
                                                        <span>Available: {session.availableSeats}/{session.totalSeats}</span>
                                                    </div>
                                                    <div className="col-12 col-md pe-2 d-flex justify-content-start justify-content-md-end align-items-center">
                                                        <CButton color="dark" variant="outline" shape="rounded-pill" href={'/programs/1'} target="_blank">Register</CButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </CAccordionBody>
                            </CAccordionItem>
                        </CAccordion>
                    )
                }
            </div>
        </>
    )

}

export default Programs;
