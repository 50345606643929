import React, { useEffect, useState } from "react";
import { CForm, CCol, CFormInput, CFormLabel } from "@coreui/react";
import ProShopItems from "../components/ProShopItems";

const ProShop = () => {
    const itemsData = [
        {
            name: "Performance T-shirt",
            price: 50,
            images: "https://i.postimg.cc/pd48JGLH/t-shirt-3.jpg"
        },
        {
            name: "Basketball Shorts",
            price: 35,
            images: "https://i.postimg.cc/3rgp2ZMs/shorts-3.jpg"
        },
        {
            name: "Sweatband",
            price: 10,
            images: "https://i.postimg.cc/j2XLmZtC/sweat-band-3.jpg"
        },
        {
            name: "Fitness Towel",
            price: 20,
            images: "https://i.postimg.cc/153t40yc/towel-2.jpg"
        },
        {
            name: "Water Bottle",
            price: 45,
            images: "https://i.postimg.cc/J4FP2qnp/water-bottle-3.jpg"
        },
        {
            name: "Yoga Mat",
            price: 60,
            images: "https://i.postimg.cc/QdbVM6Tb/yoga-mat-2.jpg"
        },
        {
            name: "Padlock",
            price: 10,
            images: "https://i.postimg.cc/XqdY6xW9/pad-lock-2.jpg"
        },
        {
            name: "Energy Bar",
            price: 3.5,
            images: "https://i.postimg.cc/y8VY2FbS/energy-bar-2.jpg"
        },


    ];

    const [items, setItems] = useState([]);
    const [filters, setFilters] = useState({});

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const applyFilters = (venues, filters) => {
        return venues.filter((venue, index) => {
            if (filters.venueName) {
                const searchTerm = filters.venueName.toLowerCase();
                if (!(venue.name.toLowerCase().includes(searchTerm))) {
                    return false;
                }
            }
            return true;
        });
    };

    useEffect(() => {
        setItems(applyFilters(itemsData, filters));
    }, [itemsData, filters]);

    return (
        <>
            <div class="container pt-2">
                <CForm className="row w-100 gy-2 gx-3 align-items-end py-3">
                    <CCol className="col-6 col-md-3">
                        <CFormLabel htmlFor="venueName" className="mb-1">Name</CFormLabel>
                        <CFormInput id="venueName" name="venueName" placeholder="Search..." 
                            style={{borderRadius: "2rem"}} onChange={updateFilters}
                        />
                    </CCol>
                </CForm>
            </div>
            <div className="px-5">
                <hr/>
            </div>
            <ProShopItems
                items={items}
                />
        </>
    )

}

export default ProShop;
