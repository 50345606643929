import React from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";

const ProShopItems = ({items}) => {
    const [showMore, setShowMore] = useState(false);

    function showAll() {
        setShowMore(true);
        const btn = document.getElementById('more_btn');
        btn.style.display = 'none';
    }

    const itemCards = (items) => {
        return items.map((item, key) => (
            <>
                <div className='col-lg-3 col-md-4' key={key}>
                    <div className='card border-0' >
                        <div className='card_img'>
                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Navigation, Pagination]}
                                className="mySwiper"
                            >
                                {
                                    item?.images?.length > 0 &&
                                    item?.images?.split(",").map((image, minKey) => {
                                        // console.log("check data" , image)
                                        return <>
                                            <div key={minKey}
                                                    className={"carousel-item active"}>
                                                <SwiperSlide><img className='w-100 d-block'
                                                                    src={image}/></SwiperSlide>
                                            </div>
                                        </>
                                    })
                                }

                            </Swiper>
                            <div class="price-box">{`$${item.price}`}</div>
                            
                        </div>
                        <div class="card_body">
                            <div class="star_name">
                                <h3 className="title-trunc">
                                    <a style={{color: "inherit"}}>{item.name}</a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ))
    }

    return (
        <>
            <section className="venues pt-0 pb-5">
                <div className="container">
                <div className="py-2"/>
                    <div className="row gy-3">
                        {itemCards(items?.slice(0, 8))}
                        {/* load all */}
                        {
                            items?.length > 8 ? (
                                <>
                                    {showMore && itemCards(items?.slice(8))}

                                    <div className="col-12 ">
                                        <a className="more_btn" id = "more_btn" onClick={() => showAll()}>Load More</a>
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </section>
        </>
    )
}


export default ProShopItems;