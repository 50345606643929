import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import {useNavigate} from "react-router-dom";
import DataTable from "react-data-table-component";

const RequestForms = () => {
    document.title = "Request Forms | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your request forms with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);

    const [search, setSearch] = useState("");

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Request Forms</h2>
                            {/*<p className="modal-subtitle mb-0">Lorem ipsum</p>*/}
                        </div>
                        <div>
                            <CButton color="light" href={`/business/request-forms/0`}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Request Form
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row flex-wrap align-items-center">
                        <div class="pe-2 pb-3">
                            <CFormInput placeholder="Search..." onChange={
                                (event) => setSearch(event.target.value)
                            }/>
                        </div>
                    </div>
                    <DataTable
                        columns={[
                            {
                                name: "Name",
                                selector: row => row.name,
                            },
                            {
                                name: '',
                                cell: row => (
                                    <div class="py-2">
                                        <CButton color="dark" variant="outline" href={`/business/request-forms/${row.id}`} target="_blank">
                                            Edit
                                        </CButton>
                                    </div>
                                ),
                                minWidth: "100px",
                                right: true,
                            },
                        ]}
                        data={[
                            {
                                name: "Equipment Checkout",
                                id: "123",
                            },
                            {
                                name: "Membership Registration",
                                id: "456",
                            },
                            {
                                name: "Court & Pool Reservation",
                                id: "456",
                            },
                            {
                                name: "Facility Rental Request",
                                id: "456",
                            },
                        ]}
                        pagination
                    />
                </CCardBody>
            </CCard>
        </div>
    );
}

export default RequestForms;
