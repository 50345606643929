import React, { useEffect, useState } from "react";
import {CButton} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import {getPublicProviderProfile, SITE_URL} from "../../redux/action/action";
import BannerSection from "../Layout/Home/BannerSection";
import ErrorPage from "../../ErrorPage";
import Membership from "./Membership";
import Programs from "./Programs"
import ProviderVenues from "./ProviderVenues";
import ProShop from "./ProShop";
import {resetNavbarBrand, updateNavbarBrand} from "../../redux/action/accountModals";

const OrganizationHome = () => {
    const dispatch = useDispatch();

    const providerDetail = useSelector(state => state.Apis.providerDetail);
    const providerDetailSuccess = useSelector(state => state.Apis.providerDetailSuccess);
    const { name, role } = useSelector(state => state.Login.userDetail);
    
    const [providerProfile, setProviderProfile] = useState({});
    const [programsModuleVisible, setProgramsModuleVisible] = useState(true);
    const [rentalsModuleVisible, setRentalsModuleVisible] = useState(false);
    const [membershipModuleVisible, setMembershipModuleVisible] = useState(false);
    const [proShopModuleVisible, setProShopModuleVisible] = useState(false);

    const onClick = (state) => {
        setProgramsModuleVisible(false);
        setRentalsModuleVisible(false);
        setMembershipModuleVisible(false);
        setProShopModuleVisible(false);
        switch (state) {
            case "programs":
                setProgramsModuleVisible(true);
                break;
            case "rentals":
                setRentalsModuleVisible(true);
                break;
            case "membership":
                setMembershipModuleVisible(true);
                break;
            case "proShop":
                setProShopModuleVisible(true);
                break;
            default:
                setProgramsModuleVisible(true);
        }
    }

    useEffect(() => {
            if (providerDetailSuccess) {
                setProviderProfile(providerDetail);
                document.title = `${providerDetail.name} | DoubleSpot`;
                document.querySelector('meta[name="description"]').setAttribute(
                    "content",
                    `Explore what ${providerDetail.name} has to offer on DoubleSpot.`
                );
                const brandLogo = providerDetail.business_plan?.brand_logo;
                dispatch(updateNavbarBrand({
                    navbarLogo: brandLogo ? `/images/${brandLogo}` : "",
                    navbarColor: providerDetail.business_plan?.brand_color,
                    navbarButtonColor: providerDetail.business_plan?.brand_button_color,
                    navbarButtonWhiteText: providerDetail.business_plan?.brand_button_white_text,
                }));
            } else if (providerDetailSuccess === false) {
                setProviderProfile({notFound: true});
            }
    
            return () => {
                dispatch(resetNavbarBrand());
            }
        }, [providerDetailSuccess]);

    useEffect(() => {
        const sendData = {
            businessId: document.location.pathname.split("/")[2],
        };
        if (localStorage.getItem("userId") !== "undefined") {
            sendData.userId =  localStorage.getItem("userId");
        }
        dispatch(getPublicProviderProfile(sendData));
    }, [name, role, document.location.pathname]);

    if (providerProfile.notFound) {
        return <ErrorPage/>
    }



    return (
        <>
            <BannerSection
                title={providerProfile.name}
                imageSource={SITE_URL + 'images/' + providerProfile?.images}
                />
            <div class="container pt-4 flex-row">
                {providerProfile?.business_plan?.programs ?
                    <>
                        <CButton className="ps-0 text-button"><h2 
                                    className="section_heading pb-0" 
                                    onClick={() => onClick("programs")} 
                                    style={{color: programsModuleVisible? "black" : "gray"}}
                                    >Programs</h2></CButton> 
                        <div class="vr mt-1" style={{height: "2.5em", width: "2px"}}></div>
                    </> 
                    : null
                }
                <CButton className="text-button"><h2 
                            className="section_heading pb-0" 
                            onClick={() => onClick("rentals")}
                            style={{color: rentalsModuleVisible? "black" : "gray"}}
                            >Rentals</h2></CButton>
                {providerProfile?.business_plan?.membership ?
                    <>
                        <div class="vr mt-1" style={{height: "2.5em", width: "2px"}}></div>
                        <CButton className="text-button"><h2 
                                    className="section_heading pb-0" 
                                    onClick={() => onClick("membership")}
                                    style={{color: membershipModuleVisible? "black" : "gray"}}
                                    >Membership</h2></CButton>
                    </>
                    : null
                }   
                {providerProfile?.business_plan?.pro_shop ?
                    <>
                        <div class="vr mt-1" style={{height: "2.5em", width: "2px"}}></div>
                        <CButton className="text-button"><h2 
                                    className="section_heading pb-0" 
                                    onClick={() => onClick("proShop")}
                                    style={{color: proShopModuleVisible? "black" : "gray"}}
                                    >Pro Shop</h2></CButton>
                    </>
                    : null
                }
            </div>
            {programsModuleVisible && providerProfile?.business_plan?.programs ? 
               <Programs/> : 
               membershipModuleVisible && providerProfile?.business_plan?.membership ? 
               <Membership/> :
               proShopModuleVisible && providerProfile?.business_plan?.pro_shop ?
               <ProShop/> : <ProviderVenues/>
            }

        </>
    )

}

export default OrganizationHome;
