import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import {useNavigate} from "react-router-dom";
import SortableQuestions from "./SortableQuestions";

const RequestForm = () => {
    document.title = "Request Forms | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your request forms with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);

    const [search, setSearch] = useState("");
    const [questionOrder, setQuestionOrder] = useState([1, 2, 3, 4]);
    const [questions, setQuestions] = useState([
        {
            prompt: "What is the name of your organization?",
            type: '1',
            optional: false,
        },
        {
            prompt: "Please describe the purpose of your event and any special requirements you may have.",
            type: '2',
            optional: true,
        },
        {
            prompt: "How many tables will you need?",
            type: '3',
            min: 0,
            max: 20,
            optional: false,
        },
        {
            prompt: "Will you require audio/video setup?",
            type: '4',
            options: "Yes, No",
            optional: false,
            multi: true,
        }
    ]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Equipment Checkout Form</h2>
                            {/*<p className="modal-subtitle mb-0">Lorem ipsum</p>*/}
                        </div>
                    </div>
                    <hr/>
                    <SortableQuestions
                        questionOrder={questionOrder}
                        setQuestionOrder={setQuestionOrder}
                        questions={questions}
                        setQuestions={setQuestions}
                    />
                </CCardBody>
            </CCard>
        </div>
    );
}

export default RequestForm;
