import React, { useEffect, useState } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardImage,
    CCardText,
    CCardTitle,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CRow
} from "@coreui/react";

const Membership = () => {
    const memberships = [
        {
            name: "Swim Season Pass",
            description: "Provides unlimited access to the pool for the entire swim season, offering a convenient and cost-effective way to enjoy recreational swimming. Perfect for individuals and families, the pass ensures fun-filled summer days with access to open swim, lap swimming, and special events. Stay cool, stay active, and make a splash all season long!",
            image: "https://i.postimg.cc/bvwN7k8C/Adobe-Stock-163138124.jpg",
            type: "Individual",
            price: "165"
        },
        {
            name: "Teen Flex Membership (Ages 14-17)",
            description: "A flexible membership designed for teens ages 14 to 17. This plan offers unlimited access to all amenities with a convenient monthly billing option. Perfect for teens looking to stay active, participate in recreational programs, and enjoy a variety of fitness and social activities.",
            image: "https://i.postimg.cc/wMCp3RCp/Adobe-Stock-118006042.jpg",
            type: "Individual",
            price: "200"
        },
        {
            name: "Adult Annual Membership (Ages 18 & Up)",
            description: "A prepaid annual membership designed for adults ages 18 and up, providing unlimited access to all amenities. Billed once per year, this membership offers a convenient and cost-effective way to stay active, participate in fitness programs, and enjoy recreational facilities year-round.",
            image: "https://i.postimg.cc/DwdTyZ59/Adobe-Stock-260048428.jpg",
            type: "Individual",
            price: "250"
        },
        {
            name: "Annual Dog Tag",
            description: "The Dog Tag grants year-round access to Barkwood Park Unleashed Dog Park at Thunder Rock Prairie, providing a safe and fun off-leash space for dogs to run, play, and socialize. This tag ensures all canine visitors are registered, promoting a clean and enjoyable environment for pets and their owners.",
            image: "https://i.postimg.cc/2578JGPB/Adobe-Stock-52330389.jpg",
            type: "Individual",
            price: "39"
        },
    ]

    const [displayedMemberships, setDisplayedMemberships] = useState([]);
    const [filters, setFilters] = useState({});

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const applyFilters = (memberships, filters) => {
        return memberships.filter(membership => {
            if (filters.name) {
                const searchTerm = filters.name.toLowerCase();
                if (!(membership.name.toLowerCase().includes(searchTerm))) {
                    return false;
                }
            }
            return true;
        });
    };

    useEffect(() => {
        setDisplayedMemberships(applyFilters(memberships, filters));
    }, [memberships, filters]);

    return (
        <>
            <div class="container pt-2">
                <CForm className="row gy-2 gx-3 align-items-end py-3">
                    <CCol className="col-5 col-md-2">
                        <CFormLabel htmlFor="name" className="mb-1">Name</CFormLabel>
                        <CFormInput name="name" placeholder="Search..."
                                    style={{borderRadius: "2rem"}} onChange={updateFilters}
                        />
                    </CCol>
                </CForm>
            </div>
            <div className="px-5">
                <hr/>
            </div>
            <div className="container pt-3">
                {
                    displayedMemberships.map(membership =>
                        <CCard className="mb-3">
                            <CCardBody className="p-2">
                                <CRow>
                                    <CCol md={3} xl={2} style={{display: "flex"}}>
                                        <CCardImage className="p-3 align-self-center" src={membership.image}  style={{borderRadius: "25px"}}/>
                                    </CCol>
                                    <CCol className="px-4 px-md-0 py-3" md={7} xl={8}>
                                        <CCardTitle>{membership.name}</CCardTitle>
                                        <CCardText>
                                            <div style={{
                                                overflow: "hidden",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 3,
                                            }}>
                                                <span>{membership.description}</span>
                                            </div>
                                            <h5 className="align-self-center pt-2 mb-0">${membership.price}</h5>
                                        </CCardText>
                                    </CCol>
                                    <CCol className="px-4 px-md-0 pb-2 pb-md-0" md={2} style={{display: "flex"}}>
                                        <div className="col-12 col-md pe-2 d-flex justify-content-start justify-content-md-center align-self-center">
                                            <CButton color="dark" variant="outline" shape="rounded-pill" target="_blank">Sign Up</CButton>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    )
                }
            </div>
        </>
    )

}

export default Membership;
