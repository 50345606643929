import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {CFormInput, CFormSelect} from "@coreui/react";
import moment from "moment"
import { Modal } from 'reactstrap';
import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    FacebookShareButton,
    LineShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    singleVenueDetail,
    singleVenuePackages,
    SITE_URL,
    DEFAULT_URL,
    setAlert,
    checkAvailability,
    singleVenueReivews,
    addToWishList
} from "../../redux/action/action";
import LocationMap from "../Layout/Alerts/LocationMap";
import Loading from "./Loading";
import ErrorPage from "../../ErrorPage"
import SignIn from "../components/SignIn";
import {
    dateAdd, 
    dateToIsoString,
    getAvailableEndTimes,
    getAvailableSlots,
    getAvailableStartTimes,
    getTimeString
} from "../../Utils/DateTimeUtils";
import {users} from "constants";
import {resetNavbarBrand, updateNavbarBrand} from "../../redux/action/accountModals";

const Venue = () => {

    const [venueDetail, setVenueDetail] = useState({})
    const [packageLists, setPackageList] = useState([])
    const [images, setImages] = useState(["noImageFound.png", "noImageFound.png", "noImageFound.png"])
    const [formData, setFormData] = useState({})
    const [totalAmount, setTotalAmount] = useState(0)
    const [timeErr, setTimeErr] = useState("")
    const [showAllImages, setShowAllImages] = useState(false)
    const [timeDiff, setTimeDiff] = useState(0)
    const [attendeesFee, setAttendeesFee] = useState(0)
    const [slotChecking, setSlotChecking] = useState({})
    const viewImages = () => setShowAllImages(!showAllImages)
    const [slotErr, setSlotErr] = useState("")
    const [rating, setRating] = useState(0)
    const [slotExistredirect, setSlotExistRedirect] = useState(false)
    const [reviews, setReviews] = useState([])
    const [reviewsMatrix, setReviewsMatrix] = useState([])
    const [shareModal, setShareModal] = useState(false);
    const toggleShareModal = () => setShareModal(!shareModal);
    const [unavailable, setUnavailable] = useState(false);
    const [venueStartTime, setVenueStartTime] = useState("");
    const [venueEndTime, setVenueEndTime] = useState("");
    const [wishlistStatus, setWishlistStatus] = useState(0);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userDetail = useSelector(state => state.Login.userDetail);
    const singleVenueDetails = useSelector(state => state.Apis.singleVenueDetail);
    const slotAlready = useSelector(state => state.Apis.slotAlreadyExist);
    const packageList = useSelector(state => state.Apis.packageList);
    const reviewListing = useSelector(state => state.Apis.singleVenueReview);
    const processFeeRate = 0.03; // Fixed number for now, will be variable in the future

    //for disable the backdateform today
    const today = new Date(); // Get current date in YYYY-MM-DD format
    function getTimeDifference(start_time, end_time) {
        if (!start_time || !end_time) return 0;
        const startTime = new Date(`2023-01-01 ${start_time}`);
        const endTime = new Date(`2023-01-01 ${end_time}`);
        return (endTime.getTime() - startTime.getTime()) / (60 * 60 * 1000);
    }

    // Example usage
    const onChange = (e) => {
        const existingEvents = slotAlready.length ? slotAlready[0] : [];
        let differenceTime;
        if (e.target.name === "end_time") {
            const startTimes = getAvailableStartTimes(
                venueStartTime,
                venueEndTime,
                formData.date,
                existingEvents,
                e.target.value,
                venueDetail?.min_hours,
                venueDetail?.max_hours,
                venueDetail.hours_between_events
            );
            const startTimeElement = document.getElementById("start_time");
            startTimeElement.innerHTML = '';
            let defaultStartOption = document.createElement("OPTION");
            defaultStartOption.appendChild(document.createTextNode('select time'));
            defaultStartOption.setAttribute('value', '');
            startTimeElement.appendChild(defaultStartOption);
            startTimes.forEach(time => {
                const option = document.createElement("OPTION");
                option.appendChild(document.createTextNode(getTimeString(time, true)));
                option.setAttribute('value', getTimeString(time, false));
                startTimeElement.appendChild(option);
            });
            startTimeElement.value = formData.start_time;
            if (startTimeElement.value !== formData.start_time) {
                startTimeElement.value = '';
            }

            differenceTime = getTimeDifference(formData.start_time, e.target.value);
            setTimeDiff(differenceTime);
            setTotalAmount(parseFloat(differenceTime) * venueDetail?.hourly_price + attendeesFee + venueDetail?.cleaning);
        }
        if (e.target.name === "start_time") {
            const endTimes = getAvailableEndTimes(
                venueStartTime,
                venueEndTime,
                formData.date,
                existingEvents,
                e.target.value,
                venueDetail?.min_hours,
                venueDetail?.max_hours,
                venueDetail.hours_between_events
            );
            const endTimeElement = document.getElementById("end_time");
            endTimeElement.innerHTML = '';
            let defaultEndOption = document.createElement("OPTION");
            defaultEndOption.appendChild(document.createTextNode('select time'));
            defaultEndOption.setAttribute('value', '');
            endTimeElement.appendChild(defaultEndOption);
            endTimes.forEach(time => {
                const option = document.createElement("OPTION");
                option.appendChild(document.createTextNode(getTimeString(time, true)));
                option.setAttribute('value', getTimeString(time, false));
                endTimeElement.appendChild(option);
            });
            endTimeElement.value = formData.end_time;
            if (endTimeElement.value !== formData.end_time) {
                endTimeElement.value = '';
            }

            differenceTime = getTimeDifference(e.target.value, formData.end_time);
            setTimeDiff(differenceTime);
            setTotalAmount(parseFloat(differenceTime) * venueDetail?.hourly_price + attendeesFee + venueDetail?.cleaning);
        }
        if (e.target.name === "attendees") {
            const freeAttendees = venueDetail?.free_attendees || 0;
            const pricePerAttendee = venueDetail?.price_per_attendee || 0;
            const fee = Math.max((parseInt(e.target.value) || 0) - freeAttendees, 0) * pricePerAttendee;
            setAttendeesFee(fee);
            setTotalAmount(timeDiff * venueDetail?.hourly_price + fee + venueDetail?.cleaning);
        }
        const updatedData = {[e.target.name]: e.target.value};

        if (e.target.name === "date") {
            updatedData.start_time = '';
            updatedData.end_time = '';
            setTimeDiff(0);
            setTotalAmount(attendeesFee + venueDetail?.cleaning);
            if (e.target.value === '') {
                const startTimeElement = document.getElementById("start_time");
                startTimeElement.innerHTML = '';
                let defaultStartOption = document.createElement("OPTION");
                defaultStartOption.appendChild(document.createTextNode('select time'));
                defaultStartOption.setAttribute('value', '');
                startTimeElement.appendChild(defaultStartOption);

                const endTimeElement = document.getElementById("end_time");
                endTimeElement.innerHTML = '';
                let defaultEndOption = document.createElement("OPTION");
                defaultEndOption.appendChild(document.createTextNode('select time'));
                defaultEndOption.setAttribute('value', '');
                endTimeElement.appendChild(defaultEndOption);
            } else {const defaultStartTime = '09:00:00';
                const defaultEndTime = '17:00:00';
                const date = new Date(e.target.value);
                switch (date.getUTCDay()) {
                    case 0:
                        setVenueStartTime(venueDetail?.start_time_sun ? venueDetail.start_time_sun : defaultStartTime);
                        setVenueEndTime(venueDetail?.end_time_sun ? venueDetail.end_time_sun : defaultEndTime);
                        break;
                    case 1:
                        setVenueStartTime(venueDetail?.start_time_mon ? venueDetail.start_time_mon : defaultStartTime);
                        setVenueEndTime(venueDetail?.end_time_mon ? venueDetail.end_time_mon : defaultEndTime);
                        break;
                    case 2:
                        setVenueStartTime(venueDetail?.start_time_tue ? venueDetail.start_time_tue : defaultStartTime);
                        setVenueEndTime(venueDetail?.end_time_tue ? venueDetail.end_time_tue : defaultEndTime);
                        break;
                    case 3:
                        setVenueStartTime(venueDetail?.start_time_wed ? venueDetail.start_time_wed : defaultStartTime);
                        setVenueEndTime(venueDetail?.end_time_wed ? venueDetail.end_time_wed : defaultEndTime);
                        break;
                    case 4:
                        setVenueStartTime(venueDetail?.start_time_thu ? venueDetail.start_time_thu : defaultStartTime);
                        setVenueEndTime(venueDetail?.end_time_thu ? venueDetail.end_time_thu : defaultEndTime);
                        break;
                    case 5:
                        setVenueStartTime(venueDetail?.start_time_fri ? venueDetail.start_time_fri : defaultStartTime);
                        setVenueEndTime(venueDetail?.end_time_fri ? venueDetail.end_time_fri : defaultEndTime);
                        break;
                    case 6:
                        setVenueStartTime(venueDetail?.start_time_sat ? venueDetail.start_time_sat : defaultStartTime);
                        setVenueEndTime(venueDetail?.end_time_sat ? venueDetail.end_time_sat : defaultEndTime);
                        break;
                    default:
                        setVenueStartTime(defaultStartTime);
                        setVenueEndTime(defaultEndTime);
                        break;
                }

                const venueInfo = {
                    venueIds: singleVenueDetails.id,
                    date: e.target.value,
                };
                dispatch(checkAvailability(venueInfo))
            }
        }
        setFormData({...formData, ...updatedData});
    }
    
    const buttonToggle = () => {
        const btn = document.getElementById('viewButton');

            if (btn.textContent === 'View More') {
                btn.textContent = 'View Less';
            } else {
                btn.textContent = 'View More';
            }
    }

    const onSubmitform = (e) => {
        e.preventDefault()

        const getToken = localStorage.getItem("token");
        const role = userDetail.role;
        if (!getToken || role !== users.ROLE_CUSTOMER) {
            return;
        }

        if (venueDetail.organizationalDomain.length) {
            let userEmail;
            if (userDetail.email) {
                userEmail = userDetail?.email;
            } else {
                return dispatch(setAlert('Please login with your organization email to request this venue.', 'error'));
            }
            let isMember = false;
            for (let i = 0; i < venueDetail.organizationalDomain.length; i++) {
                if (userEmail.split('@')[1] === venueDetail.organizationalDomain[i].email) {
                    isMember = true;
                    break;
                }
            }
            if (!isMember) {
                return dispatch(setAlert('Please login with your organization email to request this venue.', 'error'));
            }
        }

        const updatedFormData = {
            ...formData,
            venueId: venueDetail.id,
            differenceTime: getTimeDifference(formData.start_time, formData.end_time),
            finalPrice: +(totalAmount).toFixed(2),
            hourlyPrice: venueDetail.hourly_price,
            freeAttendees: venueDetail.free_attendees,
            pricePerAttendee: venueDetail.price_per_attendee,
            cleaningFee: venueDetail.cleaning,
            processingFee: +(totalAmount * processFeeRate).toFixed(2),
        };
        setFormData(updatedFormData);
    
        setSlotExistRedirect(false)
        localStorage.setItem("bookingData", JSON.stringify(updatedFormData))
        const fetchData = async() => {
            if (venueDetail.process_payment) {
                await fetch(DEFAULT_URL + 'getSecret', 
                {
                    method: 'GET',
                    headers: {
                        'venueid': updatedFormData.venueId,
                        'finalprice': updatedFormData.finalPrice,
                        'processing': updatedFormData.processingFee,
                    }
                }
                ).then((res) => {
                    return(res.json())
                }).then((data) => {
                    localStorage.setItem("payment_intent", data.payment_intent)
                    localStorage.setItem("client_secret", data.client_secret)
                    navigate(`/checkout/${venueDetail.id}`)
                });
            }
            else {
                localStorage.setItem("payment_intent", null)
                localStorage.setItem("client_secret", null)
                navigate(`/checkout/${venueDetail.id}`)
            }
            
        }
        fetchData();
        
    }

    useEffect(() => {
        getSingleVenueDetail()
    }, [userDetail.id])

    useEffect(() => {
        if (unavailable == true){
            const element = document.getElementById("unavailable_false");
            element.style.display = "none";
        } else {
            const element = document.getElementById("unavailable_false");

            if (element != null){
                element.style.display = "block";
            }
        }
    }, [unavailable])

    useLayoutEffect(() => {
        setVenueDetail(singleVenueDetails);
        setRating(singleVenueDetails?.average_rating);
        setSlotChecking({ ...slotChecking, ['venueId']: singleVenueDetails?.id });
        localStorage.setItem("venueId", singleVenueDetails?.id);
        const imagesArray = singleVenueDetails?.images?.split(',');
        if (imagesArray?.length) {
            setImages(imagesArray);
        }
        setPackageList(packageList);
        const brandLogo = singleVenueDetails.business_plan?.brand_logo;
        dispatch(updateNavbarBrand({
            navbarLogo: brandLogo ? `/images/${brandLogo}` : "",
            navbarColor: singleVenueDetails.business_plan?.brand_color,
            navbarButtonColor: singleVenueDetails.business_plan?.brand_button_color,
            navbarButtonWhiteText: singleVenueDetails.business_plan?.brand_button_white_text,
        }));

        return () => {
            dispatch(resetNavbarBrand());
        }
    }, [singleVenueDetails, packageList])

    const getSingleVenueDetail = () => {
        var id = parseInt(document.location.pathname.substring(7))
        const sendData = {
            venueId: id,
            userId: userDetail.id || 0
        }
        dispatch(singleVenueDetail(sendData))
        dispatch(singleVenuePackages({ venueId: id }))

    }

    function errorForHourConstraints() {
        return dispatch(setAlert("The attempted book time is not within the venue's limit.", 'error'))
    }

    function getCity(location) {
        if(location === undefined){
            return 'Undefined';
        }else{
            var addressArr = location.split(',');
            return addressArr[addressArr.length-4] + ", " + addressArr[addressArr.length-3];
        }
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "instant"});
        window.addEventListener('scroll', handleScroll);

        // Remove the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleScroll() {
        console.warn("check the scrol area")
    }


    function checkSlotAleadyExist(e) {
        setSlotChecking({ ...slotChecking, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (!slotAlready.length) return;
        if (!formData.date) return;
        const availableSlots = getAvailableSlots(
            venueStartTime,
            venueEndTime,
            formData.date,
            slotAlready[0],
            venueDetail.min_hours,
            venueDetail.hours_between_events
        );
        setUnavailable(availableSlots.startTimes.length === 0 && availableSlots.endTimes.length === 0);

        const startTimeElement = document.getElementById("start_time");
        startTimeElement.innerHTML = '';
        let defaultStartOption = document.createElement("OPTION");
        defaultStartOption.appendChild(document.createTextNode('select time'));
        defaultStartOption.setAttribute('value', '');
        startTimeElement.appendChild(defaultStartOption);
        availableSlots.startTimes.forEach(time => {
            const option = document.createElement("OPTION");
            option.appendChild(document.createTextNode(getTimeString(time, true)));
            option.setAttribute('value', getTimeString(time, false));
            startTimeElement.appendChild(option);
        });

        const endTimeElement = document.getElementById("end_time");
        endTimeElement.innerHTML = '';
        let defaultEndOption = document.createElement("OPTION");
        defaultEndOption.appendChild(document.createTextNode('select time'));
        defaultEndOption.setAttribute('value', '');
        endTimeElement.appendChild(defaultEndOption);
        availableSlots.endTimes.forEach(time => {
            const option = document.createElement("OPTION");
            option.appendChild(document.createTextNode(getTimeString(time, true)));
            option.setAttribute('value', getTimeString(time, false));
            endTimeElement.appendChild(option);
        });
    }, [slotAlready]);

    //get the reivews
    useEffect(() => {
        let venueId = localStorage.getItem("venueId")
        const sendData = {
            venueId,
            page: 1,
        }
        dispatch(singleVenueReivews(sendData))
    }, [localStorage.getItem("venueId")])

    useEffect(() => {
        //set review listing
        setReviews(reviewListing?.getReview)
    }, [reviewListing])

    useEffect(() => {
        const matrix = reviews?.reduce(function (rows, key, index) { 
            return (index % 2 == 0 ? rows.push([key]) 
              : rows[rows.length-1].push(key)) && rows;
          }, []);

        setReviewsMatrix(matrix)
    }, [reviews])

    useEffect(() => {
        var venue_desc = document.getElementById('venue_desc');
        var host_rules = document.getElementById('host_rules');
        if (venue_desc){
            venue_desc.innerHTML = venueDetail?.description;
        }

        if (venueDetail) {
            document.title = `${venueDetail.name} | DoubleSpot`;
            document.querySelector('meta[name="description"]').setAttribute(
                "content",
                `Discover information about ${venueDetail.name} on DoubleSpot. Explore all the details of the venue to plan your next event with ease.`
            );
        }
        if (host_rules) {
            host_rules.innerHTML = venueDetail?.hostrules;
        }
        setWishlistStatus(venueDetail.wishlistsStatus)
    }, [venueDetail])

    function shareFun() {
        navigator.clipboard.writeText("https://doublespot.com/venue/"+venueDetail.id)
        dispatch(setAlert("Share link copied to clipboard.", "success"))
    }

    function saveInWhisList(id, like) {
        const getToken = localStorage.getItem("token");
        const role = userDetail.role;
        if (!getToken || role !== users.ROLE_CUSTOMER) {
            return dispatch(setAlert("Please login first.", "error"))
        }
        dispatch(addToWishList({ "venueId": id }))
        setWishlistStatus(!wishlistStatus);
        if (like) {
            dispatch(setAlert('Venue removed from wishlist.', 'success'))
        } else {
            dispatch(setAlert('Venue saved to wishlist.', 'success'))
        }

    }

    if (!venueDetail || venueDetail.venueExists === 0 || venueDetail.hidden){
        return (
            <><ErrorPage /></>
        )
    }

    if (!venueDetail.venueExists || (venueDetail.id != parseInt(document.location.pathname.substring(7)))){
        return (
            <><Loading /></>
        )
    }

    var reviewRowCount = 0

    return (
        <>
            <section className="venue_page">
                <div className="container">
                    <div className="post">
                        <div className="col-12 d-flex justify-content-between align-itmes-center my-3">
                            <div className="user_name">
                                <h3 className="d-flex justify-content-start align-items-center">{venueDetail?.name}
                                    <span className="ms-2">
                                    </span>
                                </h3>
                                <span className="d-flex align-items-center"><i class="fa-solid fa-location-dot me-1"></i>{getCity(venueDetail?.location)}</span>
                            </div>
                            <div className="like_shear_box">
                                <a onClick={() => shareFun()}><i className="fa-solid fa-share-from-square"></i>Share</a>
                                <a onClick={() => wishlistStatus > 0 ? saveInWhisList(venueDetail.id, 1) : saveInWhisList(venueDetail.id, 0)}><i className={wishlistStatus > 0 ? "fa-solid fa-heart text-danger" : "fa-solid fa-heart   "}></i>Save</a>
                            </div>
                        </div>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item border-0 rounded-3 overflow-hidden">
                                <h2 className="accordion-header" id="headingOne">
                                    <div className="row gx-1">
                                        <div className="col-md-9">
                                            {
                                                images.length > 1 &&
                                                <img src={SITE_URL + 'images/' + images[0]} className="big_img" alt={SITE_URL + "images/noImageFound.png"} />
                                            }
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card_img">
                                                {
                                                    images.length >= 2 &&
                                                    <img src={SITE_URL + 'images/' + images[1]} alt={SITE_URL + "images/noImageFound.png"} />
                                                }
                                            </div>
                                            <div className="card_img mt-1">
                                                {
                                                    images.length >= 2 && <img src={SITE_URL + 'images/' + images[2]} alt={SITE_URL + "images/noImageFound.png"} />
                                                }
                                                {
                                                    images.length > 3 && <button onClick={() => {buttonToggle()
                                                        viewImages()}} className="text_on_img rounded-3" id="viewButton">View More</button>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>

                                </h2>
                                <div id="collapseOne" className={showAllImages ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body p-0">
                                        <div className="row gx-1">
                                            {
                                                images.length > 0 &&
                                                images.map((val, key) => {
                                                    if (key >= 3) {
                                                        return <>
                                                            <div className="col-md-3 col-sm-6">
                                                                <div className="card_img mt-1 ">
                                                                    <img src={SITE_URL + 'images/' + images[key]} alt="DoubleSpot.com" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container pb-4 border-bottom">
                    <div className="post_detail">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="user_name">
                                    <h3>{venueDetail?.name}</h3>
                                    <a href={`/provider/${venueDetail?.business_id}`} target="_blank">
                                        <img src={SITE_URL + 'images/' + venueDetail?.userImage} alt="DoubleSpot.com"/>
                                    </a>
                                </div>

                                <div className="description py-3 border-bottom">
                                    <p id="venue_desc"></p>
                                </div>
                                <div className={"place_offers py-3"}>
                                    <h2>Amenities</h2>
                                    <div className="row">
                                        {venueDetail?.amenities?.length > 0 &&
                                            venueDetail?.amenities.map((val) => {
                                                return <div className="col-md-6">
                                                    <div className="text_icon fw-light">
                                                        <img src={SITE_URL + 'images/' + val.icon_image} alt="DoubleSpot.com" />
                                                        {val.name}
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    venueDetail.hostrules ?
                                    <div className="place_offers py-3 border-top">
                                        <h2 id="host-rules">Host Rules</h2>
                                        <p id="host_rules"/>
                                    </div> :
                                    null
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="book_card box_shadow px-3 py-4 rounded-4">
                                    <span className="text-danger">{slotErr}</span>
                                    <div>
                                        {(venueDetail.process_payment || venueDetail.hourly_price) ?
                                            <div className="heading">
                                                {
                                                    venueDetail?.min_hours && venueDetail?.max_hours && venueDetail?.min_hours === venueDetail?.max_hours ?
                                                        <>
                                                            <h3>{venueDetail.process_payment ? 
                                                                    `$${venueDetail?.hourly_price * venueDetail?.min_hours}` : 
                                                                    venueDetail.hourly_price ? 
                                                                        "Contact for Pricing" : 
                                                                        null
                                                                }
                                                            </h3>
                                                            <small>
                                                                {venueDetail?.min_hours} hrs total
                                                            </small>
                                                        </> :
                                                        <>
                                                            <h3>{venueDetail.process_payment ? 
                                                                    `$${venueDetail?.hourly_price}/hr` : 
                                                                    venueDetail.hourly_price ? 
                                                                        "Contact for Pricing" : 
                                                                        null
                                                                }
                                                            </h3>
                                                            <small>
                                                                {venueDetail.min_hours ? `${venueDetail.min_hours} hr min` : ""}
                                                                {venueDetail.min_hours && venueDetail.max_hours ? ", " : ""}
                                                                {venueDetail.max_hours ? `${venueDetail.max_hours} hr max` : ""}
                                                            </small>
                                                        </>
                                                }
                                            </div> : 
                                            null
                                        }
                                    </div>
                                    <form onSubmit={onSubmitform}>
                                        <div className="form-group">
                                            <label for="date" className="mt-0">Date</label>
                                            <CFormInput
                                                className="box_shadow"
                                                type="date"
                                                name="date"
                                                min={dateToIsoString(dateAdd(
                                                    today,
                                                    venueDetail.days_before ? venueDetail.days_before : 0
                                                ))}
                                                max={venueDetail.use_absolute_date ? 
                                                    venueDetail.until_date : 
                                                    venueDetail.months_ahead ? dateToIsoString(dateAdd(
                                                    today, venueDetail.months_ahead * 30
                                                )) : undefined}
                                                required
                                                onChange={(e) => {
                                                    onChange(e)
                                                    checkSlotAleadyExist(e)
                                                }}
                                            />
                                        </div>
                                        { unavailable == true && 
                                            <>
                                                <div className="no_available_times">There are no available times for {formData.date}. Please select a different date.</div>
                                            </>
                                        }
                                        <div id="unavailable_false">
                                            <div className="row mb-2">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="start_time">Start Time</label>
                                                        <CFormSelect className="box_shadow rounded-4" name="start_time"
                                                                     id="start_time" onChange={(e) => {
                                                            onChange(e)
                                                        }}>
                                                            <option value="">select time</option>
                                                        </CFormSelect>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="end_time">End Time</label>
                                                        <CFormSelect className="box_shadow rounded-4" name="end_time"
                                                                     id="end_time" onChange={(e) => {
                                                            onChange(e)
                                                        }}>
                                                            <option value="">select time</option>
                                                        </CFormSelect>
                                                    </div>
                                                    <span className="text-danger">{timeErr}</span>
                                                </div>
                                            </div>
                                            <span style={{fontSize: "14px", color: "gray"}}>
                                                {formData?.date ? "Only times slots without conflicting events will be shown." : "Time slots will be shown after selecting a date."}
                                            </span>
                                        </div>

                                        <div className="form-group">
                                            <label for="attendees">Attendees {
                                                (venueDetail?.max_attendees || venueDetail?.price_per_attendee) ?
                                                    "(" + (venueDetail?.price_per_attendee ? `$${venueDetail?.price_per_attendee} per attendee` : "") +
                                                    (venueDetail?.max_attendees && venueDetail?.price_per_attendee ? ", " : "") +
                                                    (venueDetail?.max_attendees ? `max ${venueDetail?.max_attendees} attendees` : "") + ")"
                                                    : null
                                            }</label>
                                            <CFormInput
                                                type="number"
                                                className="form-control box_shadow"
                                                min={1}
                                                max={venueDetail.max_attendees > 0 ? venueDetail.max_attendees : 4000} // Add the max attribute with the value 4000
                                                name="attendees"
                                                required
                                                onChange={onChange}
                                            />
                                        </div>
                                        {   
                                            venueDetail.process_payment ?
                                            <div className="price_list">
                                                <label for="price" className="mb-0">Price</label>
                                                <ul className="list-unstyled">
                                                    <li><small>${venueDetail?.hourly_price} x {timeDiff} hrs</small>
                                                        <small>${(venueDetail.hourly_price ? venueDetail.hourly_price * timeDiff : 0).toFixed(2)}</small>
                                                    </li>
                                                    {
                                                        venueDetail?.free_attendees ?
                                                            <>
                                                                <li>
                                                                    <small>{venueDetail?.free_attendees} free
                                                                        attendees</small>
                                                                    <small>$0.00</small>
                                                                </li>
                                                                <li>
                                                                    <small>${venueDetail?.price_per_attendee ? venueDetail?.price_per_attendee : 0} x {
                                                                        Math.max((formData.attendees ? formData.attendees : 0) - venueDetail?.free_attendees, 0)
                                                                    } additional attendees</small>
                                                                    <small>${attendeesFee.toFixed(2)}</small>
                                                                </li>
                                                            </> :
                                                            <li>
                                                                <small>${venueDetail?.price_per_attendee ? venueDetail?.price_per_attendee : 0} x {
                                                                    Math.max((formData.attendees ? formData.attendees : 0) - venueDetail?.free_attendees, 0)
                                                                } attendees</small>
                                                                <small>${attendeesFee.toFixed(2)}</small>
                                                            </li>
                                                    }
                                                    <li><small>Cleaning </small>
                                                        <small>${(venueDetail?.cleaning || 0).toFixed(2)}</small>
                                                    </li>
                                                    <li><small>DoubleSpot™ Service Fee</small>
                                                        <small>${+(totalAmount * processFeeRate).toFixed(2)}</small>
                                                    </li>
                                                </ul>
                                            </div> :
                                            null
                                        }

                                        {
                                            venueDetail.process_payment ?
                                            <div className="tow_line">
                                                <strong>Total</strong>
                                                <strong>${+(totalAmount * (1 + processFeeRate)).toFixed(2)}</strong>
                                            </div> :
                                            null
                                        }
                                                
                                        {
                                            venueDetail.hostrules ?
                                            <div className="form-group">
                                                <label className="form-check-label checkbox-label" for="agree">
                                                    <input className="form-check-input" type="checkbox" id="agree" required />
                                                    &nbsp;&nbsp;By checking this box, I acknowledge that I have read and agree to abide by the <a href="#host-rules" className="text-black"><u>host rules</u></a>.
                                                </label>
                                            </div> :
                                            null
                                        }
                                        
                                        <div className="form-group mt-3">
                                            {
                                                userDetail.role != users.ROLE_CUSTOMER ? 
                                                <SignIn btn="Request"/>

                                                :

                                                    timeDiff < venueDetail?.min_hours || timeDiff <= 0 ?
                                                        <span
                                                            className="btn form-control rounded-pill"
                                                            onClick={() => errorForHourConstraints()}
                                                        >Request</span> :
                                                        venueDetail.max_hours && timeDiff > venueDetail.max_hours ?
                                                            <span
                                                                className="btn form-control rounded-pill"
                                                                onClick={() => errorForHourConstraints()}
                                                            >Request</span> :
                                                            <button type="submit"
                                                                    className="btn form-control rounded-pill">Request</button>
                                            }
                                        </div>
                                    </form>
                                    <div className="card_footer mt-3 d-none">
                                        <h5><img src="./../../assets/icons/thander.png" alt="DoubleSpot.com" /> Instant Book</h5>
                                        <p className="ps-3">Your booking will be instantly confirmed and you can cancel
                                            for free within 24 hours,</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="view_rate pt-4 pb-15">
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-4">
                            <div className="venue_map">
                                <LocationMap locationId={venueDetail.placeId} size={"450px"} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card border-0">
                                <div className="card_body pt-4">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="card_top">
                                                <div className="imgs">
                                                    <a href={`/provider/${venueDetail?.business_id}`} target="_blank">
                                                        <img src={SITE_URL + 'images/' + venueDetail?.userImage}
                                                             alt="DoubleSpot.com"/>
                                                    </a>
                                                </div>
                                                <h3><a href={`/provider/${venueDetail?.business_id}`} target="_blank">{venueDetail?.userName}</a> <br />
                                                    <small>Joined in {moment(venueDetail.createdAt).format("MMMM YYYY")}</small>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal className="singInModal s_modal" isOpen={shareModal} toggle={() => toggleShareModal()}>
                <div className="modal-header border-0">
                    <button type="button" className="btn-close" onClick={toggleShareModal} aria-label="Close"><i
                        className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="modal-body px-lg-5 px-md-3 ">
                    <FacebookShareButton url={SITE_URL}>
                        <FacebookIcon className="mx-2 chr" round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton  url={SITE_URL}>
                        <TwitterIcon className="mx-2 chr" round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={SITE_URL} >
                        <WhatsappIcon className="mx-2 chr" round={true} />
                    </WhatsappShareButton>
                    <LineShareButton url={SITE_URL} >
                        <LinkedinIcon className="mx-2 chr" round={true} />
                    </LineShareButton>

                </div>
            </Modal>

        </>
    )

}


export default Venue;