import {closestCenter, DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers"
import React from "react";
import {CSS} from "@dnd-kit/utilities";
import {
    CButton,
    CCard,
    CCardBody, CCloseButton,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormSwitch,
    CFormTextarea
} from "@coreui/react";
import ReactQuill from "react-quill";
import CIcon from "@coreui/icons-react";
import {cilOptions, cilPlus} from "@coreui/icons";

const SortableItem = ({ id, question, setQuestions, setQuestionOrder }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleChange = (event) => {
        setQuestions(questions => {
            const updatedQuestions = [...questions];
            updatedQuestions[id - 1] = {
                ...updatedQuestions[id - 1],
                [event.target.name]: event.target.value
            };

            return updatedQuestions;
        });
    };

    const handleDelete = () => {
        setQuestions(questions => {
            const updatedQuestions = [...questions];
            updatedQuestions.splice(id - 1, 1);
            return updatedQuestions;
        });
        setQuestionOrder(questionOrder => {
            const updatedOrder = [];
            for (const i of questionOrder) {
                if (i < id) updatedOrder.push(i);
                else if (i > id) updatedOrder.push(i - 1);
            }

            return updatedOrder;
        });
    };

    return (
        <CCard ref={setNodeRef} style={style} className="mb-3">
            <CCardBody className="flex-row p-4 ps-1 justify-content-between align-items-center">
                <CCloseButton
                    style={{
                        position: "absolute",
                        top: "0.5rem",
                        right: "0.5rem",
                    }}
                    onClick={handleDelete}
                />
                <CButton
                    className="px-0"
                    color="secondary"
                    variant="ghost"
                    style={{height: "150px"}}
                    {...attributes}
                    {...listeners}
                >
                    <CIcon icon={cilOptions}/>
                </CButton>
                <CForm className="flex-row w-100 ps-1">
                    <div className="w-50 px-4 border-start border-end">
                        <div className="pb-3">
                            <CFormLabel>Prompt</CFormLabel>
                            <ReactQuill value={question?.prompt}/>
                        </div>
                        <div className="pb-3">
                            <CFormLabel>Type</CFormLabel>
                            <CFormSelect name="type" value={question?.type} onChange={handleChange}>
                                <option value="1">Single Line Text</option>
                                <option value="2">Multiple Line Text</option>
                                <option value="3">Number</option>
                                <option value="4">Selectable Options</option>
                            </CFormSelect>
                        </div>
                        <div className="flex-row">
                            <CFormLabel>Optional</CFormLabel>
                            <CFormSwitch className="ms-3" checked={question?.optional}/>
                        </div>
                    </div>
                    <div className={`w-50 d-flex flex-column ps-4 ${question?.type === "3" || question?.type === "4" ? "" : "justify-content-center align-items-center"}`}>
                        {
                            question?.type === "3" ?
                                <>
                                    <div className="pb-3">
                                        <CFormLabel>Minimum Value</CFormLabel>
                                        <CFormInput name="min" className="w-25" type="number" value={question?.min} onChange={handleChange}/>
                                    </div>
                                    <div className="pb-3">
                                        <CFormLabel>Maximum Value</CFormLabel>
                                        <CFormInput name="max" className="w-25" type="number" value={question?.max}
                                        onChange={handleChange}/>
                                    </div>
                                </> :
                            question?.type === "4" ?
                                <>
                                    <div className="pb-3">
                                        <CFormLabel>Comma-Separated Options</CFormLabel>
                                        <CFormTextarea value={question?.options}/>
                                    </div>
                                    <div className="flex-row">
                                        <CFormLabel>Multi-Select</CFormLabel>
                                        <CFormSwitch name="multi" className="ms-3" value={question?.multi} onChange={handleChange}/>
                                    </div>
                                </> :
                            "There are no type-specific settings for this question."
                        }
                    </div>
                </CForm>
            </CCardBody>
        </CCard>
    );
};

const SortableQuestions = ({ questions, setQuestions, questionOrder, setQuestionOrder }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
    );

    const handleDragEnd = (event) => {
        const {active, over} = event;

        if (active.id !== over.id) {
            setQuestionOrder((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }

    const handleCreate = (index) => {
        setQuestions(questions => {
            return [
                ...questions,
                {
                    prompt: "",
                    type: '1',
                    optional: false,
                },
            ];
        });
        setQuestionOrder(questionOrder => {
            const updatedOrder = [...questionOrder];
            updatedOrder.splice(index, 0, questionOrder.length + 2);
            return updatedOrder;
        });
    };

    return <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
    >
        <SortableContext
            items={questionOrder}
            strategy={verticalListSortingStrategy}
        >
            <div className="flex-row mb-3">
                <div className="flex-grow-1 me-3">
                    <hr/>
                </div>
                <CButton
                    className="py-0"
                    color="secondary"
                    variant="ghost"
                    onClick={() => handleCreate(0)}
                >
                    <CIcon icon={cilPlus}/>&nbsp;Add Question
                </CButton>
                <div className="flex-grow-1 ms-3">
                    <hr/>
                </div>
            </div>
            {questionOrder.map((id, index) => {
                return <>
                    <SortableItem
                        key={id}
                        id={id}
                        question={questions[id - 1]}
                        setQuestions={setQuestions}
                        setQuestionOrder={setQuestionOrder}
                    />
                    <div className="flex-row mb-3">
                        <div className="flex-grow-1 me-3">
                            <hr/>
                        </div>
                        <CButton
                            className="py-0"
                            color="secondary"
                            variant="ghost"
                            onClick={() => handleCreate(index + 1)}
                        >
                            <CIcon icon={cilPlus}/>&nbsp;Add Question
                        </CButton>
                        <div className="flex-grow-1 ms-3">
                            <hr/>
                        </div>
                    </div>
                </>
            })}
        </SortableContext>
    </DndContext>;
}

export default SortableQuestions;
